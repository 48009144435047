// 名单收集http

import { get, post, postParams, postUrl } from './http';

// 查询订单详情
export function http_billInfo(billId) {
	return postUrl('/insurance/api/order/queryUserBillInfoById', billId);
}

// 提交数据
export function http_submitAssured(data) {
	return post('/insurance/api/insure/addInsured', data);
}

// 根据分享标识查询名单收集基础信息(免登录接口)
export function http_getInsListCollectionByShareCode(shareCode) {
	return get(`/insurance/api/insListCollection/getInsListCollectionByShareCode/${shareCode}`);
}

// 名单录入(免登录接口)
export function http_addAssuredInfo(shareCode, insListAssuredDto) {
	return post(`/insurance/api/insListCollection/addAssuredInfo/${shareCode}`, insListAssuredDto);
}
