<style lang="scss" scoped>
.info {
	font-size: 0.28rem;
	text-align: center;
	color: $font_color_info;
	margin-bottom: 0.6rem;
}
.btn_box {
	margin-top: 0.6rem;
	padding: 0.3rem;
}
.error_text {
	font-size: 0.36rem;
	text-align: center;
	margin-top: 20vh;
}
.instruction {
	padding: 0.3rem;
	font-size: 0.2rem;
	.blod {
		font-weight: bold;
	}
}
.success_info {
	padding: 0.3rem;
	margin-top: 0.8rem;
	text-align: left;
	font-weight: normal;
	font-size: 0.28rem;
	li {
		padding: 0.06rem 0;
	}
	.blod {
		font-weight: bold;
	}
	.row {
		display: flex;
		align-items: center;
		.value {
			margin-left: 0.1rem;
			color: $font_color_info;
		}
	}
}
</style>

<template>
	<div class="index">
		<template v-if="!isSuccess">
			<template v-if="!errorShow">
				<template v-if="contentShow">
					<page-header>{{ billInfo.activityName }}</page-header>
					<div class="info" v-text="`${getDate(billInfo.startTime)} 至 ${getDate(billInfo.endTime)}`">2021-05-01 至 2021-05-05</div>
					<insuredFormjw :proId="billInfo.proId" :isOverseas="isOverseas" :careerData="careerData" :hasProfession="!!professionInfo?.length" ref="form"></insuredFormjw>
					<div class="btn_box">
						<van-button type="info" :loading="submitLoading" loading-text="提交中..." block @click="submit">提交</van-button>
					</div>

					<ul class="instruction">
						<li class="blod">说明：</li>
						<li>本次名单采集只适用于（{{ billInfo.activityName }}）的活动保险购买使用，我们将对您的信息进行严格的保密，并且只用于本次活动保险购买，请放心填写；</li>
						<li>多人投保时，请多次扫码填写。</li>
					</ul>
				</template>
			</template>
			<template v-else>
				<p v-text="errorText" class="error_text"></p>
			</template>
		</template>
		<success-page v-else>
			<div class="iocn_text">提交成功</div>
			<ul class="success_info">
				<li class="blod">您的投保信息已经提交成功</li>
				<li class="row">
					<span class="label">被保险人姓名：</span>
					<span class="value" v-text="submitInfo.userName"></span>
				</li>
				<li class="row">
					<span class="label">被保险人证件号码：</span>
					<span class="value" v-text="submitInfo.cardNum"></span>
				</li>
			</ul>
		</success-page>
	</div>
</template>

<script>
import insuredFormjw from '@/components/insuredFormjw';
import pageHeader from '@/components/pageHeader';
import successPage from '@/components/successPage';
import { Button, Toast } from 'vant';
import { http_addAssuredInfo, http_getInsListCollectionByShareCode } from '@/request/insuredsCollect';
import { cardType } from '@/config/fixedParams';
import {http_getProductConfig} from "@/request/insureV2"
import { loginNoPwd} from '@/request/api';

export default {
	name: 'index',
	components: {
		insuredFormjw,
		pageHeader,
		successPage,
		[Button.name]: Button,
	},
	data() {
		return {
			isSuccess: false,
			billInfo: {},
			contentShow: false,
			errorText: '',
			errorShow: false,
			submitLoading: false,
			submitInfo: {},
			isOverseas: false,
			professionInfo:[],
			careerData:[]
		};
	},
	created() {
		this.getInsListCollectionByShareCode();
	},
	methods: {
		// 获取信息
		getInsListCollectionByShareCode() {
			http_getInsListCollectionByShareCode(this.$route.query.shareCode).then(async res => {
				this.billInfo = res;
				this.isOverseas = res.level2Name ? res.level2Name.includes('境外') : false;
				this.contentShow = true;
				await loginNoPwd(res.userDesc)
				if(res.versions==1){
					const data=await http_getProductConfig({proId:res.proId})
					const group=data.insFormGroup2VoList.filter(item=>item.groupCode=='assured')[0]
					this.professionInfo=group.insForm2VoList.filter(item=>item.fieldName=='profession')
					if(this.professionInfo?.length){
						// 获取职业信息
						await this.$store.dispatch('getCareer');
						this.careerData = this.filterTree(this.$store.state.insuranceV2.careerList, JSON.parse(this.professionInfo[0].optionalValue));

					}

				}
			});
			
		},

		// 过滤数据
		filterTree(treeData, codeList) {
			let t = JSON.parse(JSON.stringify(treeData));
			function traverseTree(tree, codes, parent = null) {
				for (let i = tree?.length - 1; i >= 0; i--) {
					const node = tree[i];
					if (node?.children && node.children.length) {
						// 存在子节点
						// 继续判断子节点
						traverseTree(node.children, codes, tree);
					} else {
						if (!codes.includes(node?.professionCode)) {
							tree.splice(i, 1);
							traverseTree(parent, codes);
						}
					}
				}
			}
			traverseTree(t, codeList);

			return t;
		},

		// 获取日期
		getDate(dateTimeStr) {
			if (dateTimeStr) {
				return dateTimeStr.split(' ')[0];
			}
			return '';
		},

		submit() {
			this.$refs.form.validate().then(() => {
				let data = this.$refs.form.getData();
				this.submitInfo = data;
				let send = {
					birthday: this.$base.submitDateFormatter(data.birthday),
					certificateContent: data.cardNum,
					certificateType: cardType.filter(item => item.label === data.cardType)[0].value,
					mobile: data.phone,
					name: data.userName,
					sex: data.sex,
					englishName: `${data.familyNameSpell} ${data.firstNameSpell}`,
					profession:data?.profession
				};
				this.submitLoading = true;
				http_addAssuredInfo(this.$route.query.shareCode, send)
					.then(res => {
						this.isSuccess = true;
					})
					.finally(() => {
						this.submitLoading = false;
					});
			});
		},
	},
};
</script>
