<style lang="scss" scoped>
.icon {
	width: 1.45rem;
	height: 1.45rem;
	margin: 1.6rem auto 0.5rem;
	background: url(../assets/icon/success.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
}
.text {
	font-size: 0.3rem;
	color: #052433;
	line-height: 0.3rem;
	font-weight: bold;
	text-align: center;
}
</style>

<template>
	<div class="success_page">
		<div class="icon"></div>
		<div class="text">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'successPage',
	data() {
		return {};
	},
};
</script>
