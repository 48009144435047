<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

::v-deep .van-field__error-message{
	display: none;
}
.btns {
	margin-top: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.btn {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.3rem;
		line-height: 0.9rem;
		text-align: center;
		border-radius: 0.08rem;
	}
	.btn_cancel {
		border: 1px solid $color_main;
		color: $color_main;
		background-color: #fff;
		margin-right: 0.4rem;
	}
	.btn_sure {
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
}
</style>

<template>
	<div>
		<van-form ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="false">
				<van-field label="姓名" v-model.trim="userInfo.userName" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.userName" placeholder="请填写被保人姓名" />
				<van-field label="英文姓" v-if="isOverseas" v-model.trim="userInfo.familyNameSpell" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.familyNameSpell" placeholder="请填写被保人英文姓" />
				<van-field label="英文名" v-if="isOverseas" v-model.trim="userInfo.firstNameSpell" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.firstNameSpell" placeholder="请填写被保人英文名" />
				<van-field label="证件类型" :value="userInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择证件类型" @click="cardTypePop = true" />
				<van-field label="证件号码" v-model.trim="userInfo.cardNum" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" @input="cardNumChange" :rules="rules.cardNum" placeholder="请填写被保人证件号码" />
				<van-field label="出生日期" :value="userInfo.birthday" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.birthday" placeholder="请选择出生日期" @click="birthdayPop = true" />
				<van-field label="性别" :value="userInfo.sex" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.sex" placeholder="请选择性别" @click="sexPop = true" />
				<van-field label="手机号码" v-model.trim="userInfo.phone" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link placeholder="非必填" :rules="rules.phone" />
				<van-field v-if="hasProfession" v-model="career" is-link readonly label="职业" placeholder="请选择职业" :rules="rules.profession" @click="show = true"/>
					<van-popup v-model="show" round position="bottom">
					<van-cascader
						v-model="userInfo.profession"
						title="请选择职业"
						:options="careerData"
						@close="show = false"
						:field-names="{text:'professionName',value:'professionCode',children:'children'}"
						@finish="onFinish"
					/>
					</van-popup>

				</van-cell-group>
		</van-form>

		<!-- 弹出层 -->
		<!-- 证件类型 -->
		<van-popup v-model="cardTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="证件类型" show-toolbar value-key="label" :visible-item-count="3" :columns="cardTypeList" @confirm="cardTypeCheck" @cancel="cardTypePop = false" />
		</van-popup>
		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker v-model="birthday" type="date" title="选择年月日" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>
		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="性别" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Cell, CellGroup,Cascader, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, Form } from 'vant';
import { cardType, sexs } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import { http_insTranslate } from '@/request/common';

export default {
	name: 'addUser',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[Form.name]: Form,
		[Cascader.name]: Cascader,

	},
	props: {
		isOverseas: {
			type: Boolean,
			default: false,
		},

		proId: {
			type: Number | String,
			default: true,
		},

		careerData:{
			type: Array,
			default:()=>[]
		},

		hasProfession:{
			type:Boolean,
			default:false
		}
	},
	watch: {
		'userInfo.userName': {
			handler: function (val) {
				if (this.isOverseas) {
					http_insTranslate([val]).then(res => {
						this.userInfo.familyNameSpell = res[val]?.split(" ")[0];
						this.userInfo.firstNameSpell = res[val]?.split(" ")[1];
					});
				}
			},
			deep: true,
		},
		hasProfession:{
			handler(val){
				if(val){
					this.$set(this.rules,"profession",[{ required: true, message: '请选择职业' }])
				}
			},
			immediate:true
		}
	},
	data() {
		return {
			// 投保人信息
			userInfo: {
				userName: '',
				cardType: '',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				familyNameSpell: '',
				firstNameSpell: '',
				profession:""
			},
			rules: {
				userName: [{ required: true, message: '' }],
				cardNum: [
					{ required: true, message: '' },
					{ validator: this.cardNumCheck, message: '证件号码有误' },
				],
				birthday: [{ required: true }],
				sex: [{ required: true }],
				phone: [{ validator: this.phoneCheck, message: '请填写正确的手机号码' }],
				familyNameSpell: [{ required: true, message: '' }],
				firstNameSpell: [{ required: true, message: '' }],
			},
			userTypePop: false,
			cardTypePop: false,
			birthdayPop: false,
			userTypeList: [],
			cardTypeList: [],
			birthdayMinDate: new Date('1900-01-01'),
			birthday: new Date(),
			sexPop: false,
			sexList: [],
			show:false,
			career:""
		};
	},
	created() {
		this.initFixedParams();
		console.log(this.proId, 'sss');
	},
	methods: {
		// 初始化固定参数
		initFixedParams() {
			// this.cardTypeList = cardType;
			this.fixtureType();
			this.userInfo.cardType = cardType[0].label;
			this.sexList = sexs;
		},

		// 是不是海湾九州境内游
		fixtureType() {
			if (this.proId == 27555) {
				this.cardTypeList = [
					{
						value: '01',
						label: '身份证',
					},
				];
			} else {
				this.cardTypeList = cardType;
			}
		},

		cardTypeCheck(val) {
			this.userInfo.cardType = val.label;
			this.cardTypePop = false;
		},

		birthdayCheck(val) {
			this.userInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},

		sexCheck(val) {
			this.userInfo.sex = val.label;
			this.sexPop = false;
		},

		// 证件号码输入
		cardNumChange(val) {
			if (val && this.userInfo.cardType === '身份证') {
				// 处理大小写问题
				this.userInfo.cardNum = String(val).toLocaleUpperCase();
			}
			if (this.userInfo.cardType === '身份证' && Mtils.validation.isIdCard(this.userInfo.cardNum)) {
				// 计算
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;
			}
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// 证件类型校验方法
		cardNumCheck(val) {
			if (this.userInfo.cardType === '身份证') {
				return Mtils.validation.isIdCard(val);
			}
			if (this.userInfo.cardType === '护照') {
				return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
			}
			if (this.userInfo.cardType === '军官证') {
				return regular.army.test(val);
			}
			if (this.userInfo.cardType === '港澳回乡证') {
				return regular.hkCard.test(val);
			}
			if (this.userInfo.cardType === '台胞证') {
				return regular.twCard.test(val);
			}
			return true;
		},

		// 校验表单
		validate() {
			return this.$refs.form.validate();
		},

		// 获取数据
		getData() {
			return this.userInfo;
		},

		onFinish({ selectedOptions }){
			this.show=false
			const value=selectedOptions[selectedOptions.length-1]
			this.userInfo.profession=value.professionCode
			this.career=value.professionName
		}
	},
};
</script>
